// Colors
// back-ground
$bg-100: #ffffff;

// text
$tx-100: #3c3c3c;
$tx-200: #006c7f;
$tx-300: #549269;

// accent
$ac-100: #008ba3;
$ac-200: #68a67d;
$ac-300: #f4a261;
$ac-400: #ff9f68; 

// font
$font-large: cabrito-didone-extended;


// Size
// mobile m
$mobile-m: 375px;

// mobile l
$mobile-l: 425px;

// tablet
$tablet: 768px;

// laptop
$laptop: 1024px;

// desktop
$desktop: 1280px;

// desktop L
$desktop-L: 1440px;



