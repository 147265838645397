@use "../../styles/partials/Mixins.scss" as *;
@use "../../styles/partials/Global.scss" as *;
@use "../../styles/partials/Variables.scss" as *;


.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  &__content {
    background: $bg-100;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1.25rem rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
    z-index: 2;

    @include mobile-m {
      padding: 1.5rem;
    }

    @include mobile-l {
      padding: 2rem;
      max-width: 90%;
    }

    @include tablet {
      padding: 2.5rem;
      max-width: 80%; 
    }

    @include laptop {
      padding: 3rem;
      max-width: 70%; 
    }

    @include desktop {
      padding: 3.5rem;
      max-width: 60%;
    }

    @include desktop-L {
      padding: 4rem;
      max-width: 50%;
    }
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 3rem;
    cursor: pointer;

    @include mobile-m {
      font-size: 3rem; // Adjust close button size for mobile-m
    }

    @include tablet {
      font-size: 3rem; 
    }
  }

  &__header {
    font-size: 2rem;
    color: $tx-200;
    margin-bottom: 1rem;

    @include mobile-m {
      font-size: 2.5rem; 
    }

    @include tablet {
      font-size: 2.5rem; 
    }
  }

  &__description {
    font-size: 1.25rem;
    color: $tx-100;
  }

  &__options {
    display: flex;
    gap: 2rem;
    justify-content: center;
  

    @include mobile-m {
      flex-direction: column; 
      gap: 1rem; 
    }

    @include tablet {
      flex-direction: row; 
      gap: 2rem;
      font-size: 1.5rem;
    }
  }



}

