@use "../../styles/partials/Mixins.scss" as *;
@use "../../styles/partials/Global.scss" as *;
@use "../../styles/partials/Variables.scss" as *;


.header {
   position: fixed; 
   top: 0;          
   width: 100%;     
   z-index: 1000; 
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 18rem;
   background-image: linear-gradient(to bottom, #f9fcff 0%, #f9fcff 90%, rgba(213, 232, 255, 0) 100%);
   @include mobile-l{
      height: 20rem;
   }
   @include tablet {
      height: 12rem;
   }
   
   

   &__logo {
      margin-left: 1.5rem;
      width: 6rem;
      @include mobile-l {
         width: 6.5rem;
         margin-left: 2rem;
      }
      @include tablet {
         width: 9rem;
         margin-left: 2rem;
      }
      @include desktop {
         margin-left: 3rem;
      }

   }
 
   &__logo-link {
      text-decoration: none;
   }

   &__icon-cont {
      margin-right: 1rem;
      @include mobile-l {
         margin-right: 1.5rem;
      }
      @include tablet {
         margin-right: 2rem;
      }
      @include desktop {
         margin-right: 3rem;
      }

   }
   &__insta,&__facebook,&__kayak {
      margin-right: .5rem;
      @include tablet {
         width: 2.3rem;
         margin-right: 1rem;
      }
      @include desktop {
         width: 3rem;
      }

   } 
   &__kayak {
      width: 1.7rem;
      @include tablet {
         width: 2.3rem;
      }
      @include desktop {
         width: 3rem;
      }
   }
}