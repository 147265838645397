@use "../../styles/partials/Mixins.scss" as *;
@use "../../styles/partials/Global.scss" as *;
@use "../../styles/partials/Variables.scss" as *;


.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    background-color: white;
    color: $tx-100;
    font-size: 1rem;
    gap: .5rem;
    box-shadow: 0 -0.5rem 1.25rem rgba(0, 128, 255, 0.15);
    padding-bottom: 1rem;

    @include tablet {
        padding: 2rem 1rem;
        flex-direction: row;  
    }

    &__header {
        letter-spacing: .5px;
        font-weight: 600;
        margin: 0;
    }
    &__text {
        font-size: 1rem;
        font-weight: 200;
        margin: .5rem 0;
    }

    &__logo-cont {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: .5rem;
        @include tablet {
            flex-direction: row;
        }

    }
    &__logo {
        width: 9rem;
        @include tablet{
            width: 12rem;
            margin-bottom: 0.5rem;
        }
 
    }

    &__contact, &__managed-by, &__links {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__managed-link {
        cursor: pointer;
    }
    &__w-logo {
        width: 75px;
        margin-top: 1rem;
    }

    &__social {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__icon-cont {
        margin-top: .3rem;
    }

    &__insta {
        margin-right: .5rem;
    }

    .footer__link {
        color: $tx-100;
        text-decoration: none;
        margin: 0.5rem 0;

        &:hover {
            text-decoration: underline;
        }
    }
}